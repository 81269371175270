import { AppModule } from "modapp";
import type Api from "./init/api/Api";

const req = require.context("./init/", true, /^\..*\/([^/]*)\/\1.[jt]s$/i);

// Manually declared types for the dynamically loaded modules
interface TypedModules {
	api: Api;
}

// @ts-expect-error
// `modules` gets late-initialized below, so we can safely ignore the type error here.
const modules: TypedModules & Record<string, AppModule> = {
	// Add third party modules here
};

// Using the first-character-lowercase name of the js-file as module name
req.keys().forEach(key => {
	const match = key.match(/\/([^/]*)\.[jt]s$/);
	const name = match![1].charAt(0).toLowerCase() + match![1].slice(1);
	if (modules[name]) {
		throw new Error(`Duplicate module: ${key}`);
	}
	modules[name] = req(key).default;
});

export default modules;
