import './sentry';
import 'core-js/stable';
import { AppExt } from 'modapp';
import initModules from 'modules/init-modules';
import moduleConfig from 'moduleConfig';
import 'scss/index.scss';

// Create app and load core modules
let app = new AppExt(moduleConfig);

app.loadBundle(initModules).then(result => {
	console.log("[Main] Init modules: ", result);
	app.render(document.body);

	// If we are not logged in, we will get redirected.
	// Avoid loading the rest of the page to avoid ChunkLoadError reports
	// caused by the redirect.
	return result.modules.auth.getUserPromise().then(() => {

		// Load main modules
		return import(/* webpackChunkName: "main" */ 'modules/main-modules')
			.then(({ default: mainModules }) => app.loadBundle(mainModules))
			.then(result => console.log("[Main] Main modules: ", result));
	});
}).catch((err) => console.error(err));

// Make app global
window.app = app;
