import { uri } from 'modapp-utils';
import './errorScreen.scss';

/**
 * ErrorScreen shows login error messages, otherwise calls auth.authenticate.
 */
class ErrorScreen {

	constructor(app, params) {
		this.app = app;

		this.app.require([ 'auth' ], this._init.bind(this));
	}

	_init(module) {
		this.module = module;

		let q = uri.getQuery();
		if (q.error) {
			try {
				let err = JSON.parse(atob(q.error));
				this._showError(err);
			} catch (e) {
				console.error("Failed to parse error message: ", e);
				console.error(`Message: "${q.error}"`);
				this._redirect();
			}
		} else {
			this._showLoader();
			this.module.auth.authenticate().catch(err => {
				console.error("Authentication failed: ", err);
				this._showError(err);
			});
		}
	}

	render(el) {
		let e = document.createElement('div');
		e.classList.add('errorscreen');
		e.innerHTML = '<div class="errorscreen--cont">' +
			'<div class="errorscreen--wrapper">' +
				'<h3 class="errorscreen--title">Något gick fel</h3>' +
				'<div class="errorscreen--content">' +
					'<p>Ett fel inträffade när du försökte logga in:</p>' +
					'<i id="msg"></i>' +
				'</div><div class="errorscreen--footer">' +
					'<a class="errorscreen--link" id="link">Gå tillbaka</a>' +
				'</div>' +
			'</div>' +
		'</div>';
		e.querySelector("#msg").textContent = this.err.message;
		let url = window.location.href.split('?')[0];
		let l = e.querySelector("#link");
		l.href = url;
		if (url == window.location.href) {
			l.onclick = () => window.location.reload();
		}

		this.el = e;
		el.appendChild(e);
		return e;
	}

	unrender() {
		if (this.el) {
			this.el.parentNode.removeChild(this.el);
			this.el = null;
		}
	}

	_showError(err) {
		this.err = err;
		this.app.setComponent(this);
	}

	_showLoader() {
		this.app.setComponent(new class {
			render(el) {
				const spinner = document.createElement("div");
				spinner.className = 'errorscreen';
				spinner.innerHTML = '<div class="errorscreen--loader"><div class="spinner"></div></div>';
				el.appendChild(spinner);
				this.spinner = spinner;
			}
			unrender() {
				this.spinner?.remove();
			}
		});
	}

	_redirect() {
		window.location.replace(window.location.href.split('?')[0]);
	}
}

export default ErrorScreen;
